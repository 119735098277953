import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../url";

export const blogSlice = createApi({
  reducerPath: "blog",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Blog"],
  endpoints: (build) => ({
    createBlogPost: build.mutation({
      query: ({ formData }) => ({
        url: "/admin/blog/create",
        method: "POST",
        data: formData,
      }),
      invalidatesTags: ["AllBlog"],
    }),
    updateBlogPost: build.mutation({
      query: ({ blogPostId, formData }) => ({
        url: `/admin/blog/update/${blogPostId}`,
        method: "PUT",
        data: formData,
      }),
      invalidatesTags: ["AllBlog", "Retrieve"],
    }),
    retrieveSingleBlogPost: build.query({
      query: ({ blogPostId }) => ({
        url: `/admin/blog/single/${blogPostId}`,
        method: "GET",
      }),
      providesTags: ["Retrieve"],
    }),
    getAllBlogPost: build.query({
      query: ({ page = 1, limit = 12, ...otherParams }) => ({
        url: `/admin/blog/list?${new URLSearchParams({
          page,
          limit,
          ...otherParams,
        }).toString()}`,
        method: "GET",
      }),
      providesTags: ["AllBlog"],
    }),
    deleteBlogPost: build.mutation({
      query: ({ blogPostId }) => ({
        url: `/admin/blog/delete/${blogPostId}`,
        method: "DELETE",
        data: {},
      }),
      invalidatesTags: ["AllBlog"],
    }),
  }),
});

export const {
  useCreateBlogPostMutation,
  useUpdateBlogPostMutation,
  useLazyRetrieveSingleBlogPostQuery,
  useGetAllBlogPostQuery,
  useDeleteBlogPostMutation,
} = blogSlice;
