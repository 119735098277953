import ls from "localstorage-slim";
import encUTF8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

// configure localstorage-slim to use CryptoJS for Encryption/Decryption
ls.config.encrypt = true;
ls.config.secret = process.env.REACT_APP_TOKEN_SEC;

ls.config.encrypter = (data, secret) =>
  AES.encrypt(JSON.stringify(data), secret).toString();
ls.config.decrypter = (data, secret) => {
  try {
    return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
  } catch (error) {
    // incorrect secret, return the encrypted data instead / or null
    return error; // or return null;
  }
};

const loadData = ({ name = "appToken" }) => {
  try {
    const serializedState = ls.get(`${name}`);
    // console.log(serializedState);
    if (serializedState === null) {
      return undefined;
    }
    return serializedState;
  } catch (error) {
    return error;
  }
};

const saveData = ({ name, data, ttl = 28512000 }) => {
  try {
    ls.set(`${name}`, data, { ttl });
  } catch (err) {
    //
    console.log(err);
  }
};

export { loadData, saveData };
