import axios from "axios";
import { loadData } from "./token";

export const apiBaseUrl = process.env.REACT_APP_API_URL;

const makeRequest = axios.create({
  baseURL: apiBaseUrl,
  timeout: 30000,
});

// Define the endpoints that does not require the Bearer token
const unprotectedEndpoints = ["/admin/auth/login"];

// Request interceptor
makeRequest.interceptors.request.use(
  (config) => {
    // Check if the request URL is in the excluded endpoints
    if (
      !unprotectedEndpoints.some((endpoint) => config.url.startsWith(endpoint))
    ) {
      const authenticated = loadData({ name: "appToken" });

      if (authenticated) {
        // Add Authorization header for all other requests
        config.headers["Authorization"] = `Bearer ${authenticated}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: apiBaseUrl }) =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await makeRequest({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });

      return result?.data;
    } catch (axiosError) {
      const err = axiosError;
      console.log(err);
      return {
        error: {
          status: err.response?.status || 504,
          data: err.response?.data || {},
          message: err.response?.data?.message || err.message,
        },
      };
    }
  };
