import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../url";

export const authSlice = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Auth"],
  endpoints: (build) => ({
    adminSignin: build.mutation({
      query: ({ email, password }) => ({
        url: "/admin/auth/login",
        method: "POST",
        data: { email, password },
      }),
    }),
  }),
});

export const { useAdminSigninMutation } = authSlice;
