import { combineReducers } from "@reduxjs/toolkit";
import { authSlice, blogSlice, userBlogSlice } from "./slices";

const rootReducer = combineReducers({
  [authSlice.reducerPath]: authSlice.reducer,
  [blogSlice.reducerPath]: blogSlice.reducer,
  [userBlogSlice.reducerPath]: userBlogSlice.reducer,
});

export default rootReducer;
