import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { authSlice, blogSlice, userBlogSlice } from "./slices";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV === "development" ? true : false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authSlice.middleware,
      blogSlice.middleware,
      userBlogSlice.middleware,
    ]),
});
